<template>
  <div>
    <header class="header">
      <div class="container">
        <div class="header__flex">
          <div class="header__logo">
            <a class="header__logo--anchor" title="Lixtrip">
              <img
                class="header__logo--brand"
                src="/img/logo.png"
                alt="Lixtrip"
              />
            </a>
          </div>
          <user-avatar v-if="user.id" />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('user', ['user'])
  },
  name: 'layout-header',
  components: {
    UserAvatar: () => import('./components/user-avatar')
  }
}
</script>

<style></style>
